import React, { Component } from 'react'
import Fade from 'react-reveal'
import img6 from '../static/img/img6.jpg';
import { FaQuestionCircle, FaRegLightbulb, FaBolt, FaStaylinked, FaGem, FaAward, FaUsers, FaWpforms, FaUserShield, FaCheck } from "react-icons/fa";
import { Text, LanguageContext } from '../containers/Language';
import CountUp from 'react-countup';
class About extends Component {
  render() {
    return (
      <section id='about' className="bg-light">


        <Fade duration={1000}>
          <div className='site-section bg-light' id='about-section'>
            <div className='container'>
              <div className='row mb-5'>
                <div className='col-12 text-center'>
                  <h2 className='section-title mb-3'><Text tid="AboutUs" /></h2>
                </div>
              </div>
              <div id="more-details">
                <div className="row d-flex flex-wrap justify-content-center  main-home-first-section">
                  <div className="col-md-8 col-10 info-box  main-home-first-section-p ">
                    <h4 className="info-box-title bg-light"><FaQuestionCircle className="customize-icons" /> <Text tid="WHYCHOOSEEXCELLENT" /></h4>
                    <div className="content-1">
                      <Text tid="WHYCHOOSEEXCELLENTP" />
                    </div>
                  </div>

              
                </div>
                {/* <div className="wrappwer-second-section">
        <div className="p-4">
          <div className="jumbotron" style={{padding: "200px 0"}}>
              <h3 className="text-center" style={{fontSize:"42px", color:"#fff"}}>
              REACH YOUR POTENTIAL FASTER
              </h3>
              <p className="jumbotron-content">
                  We will take care of your accounting and administrative services.
              </p>
            </div>
        </div>
        </div> */}

              </div>
              <div className='row mb-5'>
                <div className='col-lg-6 first-img col-12' data-aos='fade-right'>
                </div>
                <div className='col-lg-5 ml-auto pl-lg-5 col-12' style={{ padding: " 0 25px" }}>
                  <h2 className='text-black mb-4 h3 font-weight-bold'>
                    <Text tid="AboutUsSection1Title" />
                  </h2>
                  <p className='mb-4'>
                    <Text tid="AboutUsSection1P" />
                  </p>
                  <div>
                    <h2 className='text-black mb-4 h3 font-weight-bold'>
                      <Text tid="AboutUsSection2Title" />
                    </h2>
                    <p className='mb-4'>
                      <Text tid="AboutUsSection2P" />
                    </p>
                    <ul className='ul-check mb-5 list-unstyled success'>
                      <li>
                        <span className='icon-about'>
                          <FaCheck className='customize-icons' />
                        </span>
                        <span>    <Text tid="AboutUsSection2Li1" /></span>
                      </li>
                      <li>
                        <span className='icon-about'>
                          <FaCheck className='customize-icons' />
                        </span>
                        <span>    <Text tid="AboutUsSection2Li2" /></span>
                      </li>
                      <li>
                        <span className='icon-about'>
                          <FaCheck className='customize-icons' />
                        </span>
                        <span>   <Text tid="AboutUsSection2Li3" /></span>
                      </li>
                      <li>
                        <span className='icon-about'>
                          <FaCheck className='customize-icons' />
                        </span>
                        <span>   <Text tid="AboutUsSection2Li4" /></span>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h2 className='text-black mb-4 h3 font-weight-bold'>
                      <Text tid="AboutUsSection3Title" />
                    </h2>
                    <p className='mb-4'>
                      <Text tid="AboutUsSection3P" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
        <Fade duration={1050}>
          <div className='container mb-4 mt-4'>
            <div className='row align-items-center justify-content-center mb-5 mt-5 pt-5'>
              <div className='col-lg-3 col-md-3 col-12 text-center mb-5'>
                <FaUsers className='customize-icons' />

                <h3 className='mt-4 mb-4 font-cairo-bold'>

                  <span>
                    <CountUp end={150} duration={5}  className="font-cairo-bold"/>
                  </span>
                  <Text tid="ClientsAndIncreasing" /></h3>
              </div>
              <div className='col-lg-3 col-md-3 col-12 text-center mb-5'>
                <FaWpforms className='customize-icons' />
                <h3 className='mt-4 mb-4 font-cairo-bold'>  <Text tid="MultipleAndIntegratedServices" /></h3>
              </div>
              <div className='col-lg-3 col-md-3 col-12 text-center mb-5'>
                <FaUserShield className='customize-icons' />

                <h3 className='mt-4 mb-4 font-cairo-bold'>  <Text tid="QualifiedStaffMembers" /></h3>
              </div>
            </div>
          </div>
        </Fade>
        <Fade dauration={10100}>
          <div className='feature-big'>
            <div className='container'>
              <div className='row  site-section d-flex flex-wrap'>
                <div className='col-lg-6 col-12 auditing-bg auditing-bg-1'>
                </div>
                <div className='col-lg-6 col-12 pl-lg-5 ml-auto mt-5'>
                  <div className='pr-4 pl-4 site-section-content'>
                    <h2 className='text-black'>
                      <Text tid="AboutSectionAlternateTitle1" />
                    </h2>
                    <p className='mb-4'>
                      <Text tid="AboutSectionAlternateSection1" />
                    </p>
                  </div>
                </div>
              </div>
              <div className='row site-section d-flex flex-wrap '>
                <div className='col-lg-6 order-1 order-lg-2 auditing-bg auditing-bg-2'>
                </div>
                <div className='col-lg-6 pr-lg-5 mr-auto mt-5 order-2 order-lg-1'>
                  <div className='pr-5 pl-4 site-section-content'>
                    <h2 className='text-black'>
                      <Text tid="AboutSectionAlternateTitle2" />
                    </h2>
                    <p className='mb-4'>
                      <Text tid="AboutSectionAlternateSection2" />
                    </p>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </Fade>
      </section>
    )
  }
}

export default About
