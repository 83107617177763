import React, { Component } from "react";
import teamslogo from "../static/img/teams.png";
import { Text, LanguageContext } from '../containers/Language';

class Portfolio extends Component {
  render() {
    // <Zmage alt= src={projectImage} />
    return (
      <section id="OurTeam">

        <div className="row">
          <div className="twelve columns ">
            <h1 className="our-team-title">
              <Text tid="OurTeam" />
            </h1>

            <div
              id="portfolio-wrapper"
              className="bgrid-quarters s-bgrid-thirds cf"
            >
              <div className="d-flex flex-wrap align-items-center">

                <div className="item col-lg-4 col-md-6 col-12 d-flex align-items-center justify-content-center">
                  <div className="item-wrap">
                    <div className="flip-card">
                      <div className="flip-card-inner">
                        <div className="flip-card-front">
                          <img src={teamslogo} />
                          <h1 className="team-title-position"><Text tid="TeamMemebr2Name" /></h1>
                          <span><Text tid="TeamMemebr2Title" /></span>
                        </div>
                        <div className="flip-card-back">
                          <h1 className="team-title-position"><Text tid="TeamMemebr2Name" /></h1>
                          <span><Text tid="TeamMemebr2Title" /></span>

                          <ul>
                            <li>
                              <div className="d-flex">
                                <div className="astric">*</div>
                                <div>  <Text tid="TeamMemebr2Li1" /></div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex">
                                <div className="astric">*</div>
                                <div>  <Text tid="TeamMemebr2Li2" /></div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex">
                                <div className="astric">*</div>
                                <div>  <Text tid="TeamMemebr2Li3" /></div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex">
                                <div className="astric">*</div>
                                <div>  <Text tid="TeamMemebr2Li4" /></div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex">
                                <div className="astric">*</div>
                                <div>  <Text tid="TeamMemebr2Li5" /></div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex">
                                <div className="astric">*</div>
                                <div>  <Text tid="TeamMemebr2Li6" /></div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item col-lg-4 col-md-6 col-12 d-flex align-items-center justify-content-center">
                  <div className="item-wrap">
                    <div className="flip-card">
                      <div className="flip-card-inner">
                        <div className="flip-card-front">
                          <img src={teamslogo} />
                          <h1 className="team-title-position"> <Text tid="TeamMemebr3Name" /></h1>
                          <span> <Text tid="TeamMemebr3Title" /></span>
                        </div>
                        <div className="flip-card-back">
                          <h1 className="team-title-position"> <Text tid="TeamMemebr3Name" /></h1>
                          <span> <Text tid="TeamMemebr3Title" /></span>
                          <ul>
                            <li>
                              <div className="d-flex">
                                <div className="astric">*</div>
                                <div>  <Text tid="TeamMemebr3Li1" /></div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex">
                                <div className="astric">*</div>
                                <div>  <Text tid="TeamMemebr3Li11" /></div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex">
                                <div className="astric">*</div>
                                <div>  
                                <div><Text tid="TeamMemebr3Li2" /></div>
                                <div><Text tid="TeamMemebr3Li21" /></div>
                                <div><Text tid="TeamMemebr3Li22" /></div>
                                <div><Text tid="TeamMemebr3Li23" /></div>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex">
                                <div className="astric">*</div>
                                <div>  <Text tid="TeamMemebr3Li3" /></div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item col-lg-4 col-md-12 col-12 d-flex align-items-center justify-content-center">
                  <div className="item-wrap">
                    <div className="flip-card">
                      <div className="flip-card-inner">
                        <div className="flip-card-front">
                          <img src={teamslogo} />
                          <h1 className="team-title-position"><Text tid="TeamMemebr4Name" /></h1>
                          <span><Text tid="TeamMemebr4Title" /></span>
                        </div>
                        <div className="flip-card-back">
                          <h1 className="team-title-position"><Text tid="TeamMemebr4Name" /></h1>
                          <span><Text tid="TeamMemebr4Title" /></span>
                          <ul>
                            <li>
                              <div className="d-flex">
                                <div className="astric">*</div>
                                <div>  <Text tid="TeamMemebr4Li1" /></div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex">
                                <div className="astric">*</div>
                                <div>  <Text tid="TeamMemebr4Li2" /></div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex">
                                <div className="astric">*</div>
                                <div>  <Text tid="TeamMemebr4Li3" /></div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </section>
    );
  }
}

export default Portfolio;
