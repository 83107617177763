import React, { Component } from "react";
import { FaQuestionCircle, FaRegLightbulb, FaBolt, FaStaylinked, FaGem, FaAward } from "react-icons/fa";

class MainHome extends Component {
  render() {
    return (
      <div id="more-details">
        <div className="row d-flex flex-wrap align-items-center justify-content-center main-home-first-section">
          <div className="col-md-4 col-8 info-box  main-home-first-section-p">
            <h4 className="info-box-title"><FaQuestionCircle className="customize-icons" /> WHY CHOOSE ACCOUNTING</h4>
            <div className="content-1">
              We bring you the best possible solutions for the growth and
              prosperity of your business or your personal finances, with
              Accounting you can’t go wrong.
            </div>
          </div>
          <div className="col-md-4 col-8 info-box  main-home-first-section-p offset-1">
            <h4 className="info-box-title">
            <FaRegLightbulb className="customize-icons" /> INTERNATIONAL COVERAGE</h4>
            <div className="content-2">
              Our specialised consultants are situated all over the globe.
              International knowledge of economic system grands you a unique
              business opportunities.
            </div>
          </div>
        </div>
        {/* <div className="wrappwer-second-section">
        <div className="p-4">
          <div className="jumbotron" style={{padding: "200px 0"}}>
              <h3 className="text-center" style={{fontSize:"42px", color:"#fff"}}>
              REACH YOUR POTENTIAL FASTER
              </h3>
              <p className="jumbotron-content">
                  We will take care of your accounting and administrative services.
              </p>
            </div>
        </div>
        </div> */}

        <div className="row d-flex flex-wrap align-items-center justify-content-center mt-4 mb-4">
          <div className="col-lg-3 col-md-6 col-12 text-center mb-3 text-primary">
            <FaBolt className="customize-icons customize-icons-primary mt-4 mb-4 text-second"/>
            <h3>Financial Consultation</h3>
          </div>
          <div className="col-lg-3 col-md-6 col-12 text-center mb-3 text-primary">
          <FaStaylinked className="customize-icons customize-icons-primary mt-4 mb-4 text-second"/>
            <h3>Accounting services</h3>
          </div>
          <div className="col-lg-3 col-md-6 col-12 text-center mb-3 text-primary">
          <FaGem className="customize-icons customize-icons-primary mt-4 mb-4 text-second"/>
            <h3>Tax consulting</h3>
          </div>
          <div className="col-lg-3 col-md-6 col-12 text-center mb-3 text-primary">
          <FaAward className="customize-icons customize-icons-primary mt-4 mb-4 text-second"/>
            <h3>Audit Services</h3>
          </div>
        </div>
          <div></div>
     </div>

  );
  }
}

export default MainHome;
