import React, { useEffect, useState } from 'react';
import { Fade, Slide } from "react-reveal";
import { FaMapMarkerAlt, FaPhoneAlt, FaMobileAlt } from "react-icons/fa";
import SimpleMap from "./SimpleMap";
import { Text, LanguageContext } from '../containers/Language';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Http from '../services/https-services';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { css } from "@emotion/react";
import ScaleLoader from "react-spinners/ScaleLoader";
import Moment from 'moment';


const override = css`
  display: flex;
  margin: 0 auto;
  border-color: red;
  margin-top : 20%;
  justify-content : center
`;

const Contact = () => {

  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("#000");
  const [companyName, setCompanyName] = useState("");
  const [CompanyRegistrationDate, setCompanyRegistrationDate] = useState(new Date());
  const [AvgMonthly, setAvgMonthly] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [validationMessgaeCompanyName, setCompanyNameValidationMessage] = useState(false);
  const [CompanyRegistrationDateValidationMessgae, setCompanyRegistrationDateValidationMessgae] = useState(false);
  const [AvgMonthlyValidationMessgae, setAvgMonthlyValidationMessgae] = useState(false);
  const [contactNameValidationMessgae, setContactNameValidationMessgae] = useState(false);
  const [contactEmailValidationMessgae, setcontactEmailValidationMessgae] = useState(false);
  const [subjectValidationMessgae, setSubjectValidationMessgae] = useState(false);
  const [messageValidationMessgae, setMessageValidationMessgae] = useState(false);
  const [phoneNumberValidationMessgae, setPhoneNumberValidationMessgae] = useState(false);
  const clearData = () => {
    setCompanyName("");
    setAvgMonthly("");
    setContactName("");
    setContactEmail("");
    setSubject("");
    setMessage("");
  }

  const handleOnSubmit = async (e) => {
    e.preventDefault()
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var phoneno = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
    { companyName.length == 0 ? setCompanyNameValidationMessage(true) : setCompanyNameValidationMessage(false) }

    { AvgMonthly.length == 0 ? setAvgMonthlyValidationMessgae(true) : setAvgMonthlyValidationMessgae(false) }

    { contactName.length == 0 ? setContactNameValidationMessgae(true) : setContactNameValidationMessgae(false) }

    { contactEmail.length == 0 ? setcontactEmailValidationMessgae(true) : setcontactEmailValidationMessgae(false) }

    { !contactEmail.match(mailformat) ? setcontactEmailValidationMessgae(true) : setcontactEmailValidationMessgae(false) }

    { !phoneNumber.match(phoneno) ? setPhoneNumberValidationMessgae(true) : setPhoneNumberValidationMessgae(false) }

    { subject.length == 0 ? setSubjectValidationMessgae(true) : setSubjectValidationMessgae(false) }

    { message.length == 0 ? setMessageValidationMessgae(true) : setMessageValidationMessgae(false) }

    {phoneNumber.length  == 0 ? setPhoneNumberValidationMessgae(true) : setPhoneNumberValidationMessgae(false)}

    if ((companyName.length && !validationMessgaeCompanyName)
      &&
      (AvgMonthly.length && !AvgMonthlyValidationMessgae)
      &&
      (contactName.length && !contactNameValidationMessgae)
      &&
      (contactEmail.length && !contactEmailValidationMessgae)
      &&
      (subject.length && !subjectValidationMessgae)
      &&
      (message.length && !messageValidationMessgae)
      &&
      (phoneNumber.length && !phoneNumberValidationMessgae)
    ) {

      try {
        setLoading(true)
        const { data, error } = await Http.post(`https://floating-falls-04939.herokuapp.com/send-mail`, {
          "to_email": "amir.khalaf@excellentuae.net",
          "subject": subject,
          "html": `<div><span style="font-weight : bold; margin : 0 10px">Contact name : </span><span style="font-weight : normal">${contactName}</span></div>
           <div> <span style="font-weight : bold; margin : 0 10px">Contact Email :</span>
           <span style="font-weight : normal">${contactEmail}</span>
            </div>
              <div>
              <span style="font-weight : bold; margin : 0 10px">Company name : </span>
              <span style="font-weight : normal">${companyName}</span>
               </div>
              <div>
              <span style="font-weight : bold; margin : 0 10px">Company registeration date: </span>
              <span style="font-weight : normal"> ${Moment(CompanyRegistrationDate).format('DD/MM/YYYY')}</span>
              </div>
              <div>
              <span style="font-weight : bold; margin : 0 10px">Avg Monthly Income : </span>
              <span style="font-weight : normal">${AvgMonthly}</span>
               </div>
               <div>
               <span style="font-weight : bold; margin : 0 10px">Phone Number: </span>
               <span style="font-weight : normal">${phoneNumber}</span>
                </div>
              <div>
              <span style="font-weight : bold; margin : 0 10px">Message :</span>
              <span style="font-weight : normal">${message}</span>
               </div>`
        });

        toast.success("message is sent successfully!");
        setLoading(false)
        clearData()
      } catch (error) {
        // console.log("errorrr", error)
        setLoading(false);
        clearData()
        // NotificationManager.error('Error message', 'Click me!', 5000, () => {
        //   alert('callback');
        // });
      }


    }

  }


  return (

    <section id="contact">

      {loading && (<div className='wrapper-loader'>
        <ScaleLoader color={color} loading={loading} css={override} size={10} />
      </div>)}


      <ToastContainer />

      <h2 className="contact-title text-center mb-4">

        <Text tid="ContactUs" />
      </h2>
      <Fade bottom duration={1000}>
        <div className="container contact-wrapper p-0">
          <div className="row d-flex">
            <div className="col-12"></div>
            <div className="col-md-8 col-lg-8 col-12">
              <div className="pr-4 pl-4 mr-4 mt-4">
                <h2 style={{ padding: "0 11px" }}><Text tid="ContactTellUs" /></h2>
              </div>
              <Slide left duration={1000}>
                <div className="">
                  <div
                    id="contactForm"
                    name="contactForm"

                  >

                    <div className="mr-4 m-4">
                      <label htmlFor="companyName">
                        <Text tid="CompanyName" /> <span className="required">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        value={companyName}
                        size="35"
                        id="companyName"
                        name="companyName"
                        onChange={e => setCompanyName(e.target.value)}
                      />
                      {validationMessgaeCompanyName &&
                        <div className='alert alert-danger' style={{ width: "65%" }}> <Text tid="FieldRequired" /> </div>
                      }

                    </div>

                    <div className="mr-4 m-4">
                      <label htmlFor="CompanyRegistrationDate">
                        <Text tid="CompanyRegistrationDate" /> {" "}
                        <span className="required">*</span>
                      </label>
                      <DatePicker selected={CompanyRegistrationDate} onChange={(date) => setCompanyRegistrationDate(date)} />

                      {CompanyRegistrationDateValidationMessgae &&
                        <div className='alert alert-danger' style={{ width: "65%" }}> <Text tid="FieldRequired" />  </div>
                      }
                    </div>

                    <div className="mr-4 m-4">
                      <label htmlFor="AvgMonthly">
                        <Text tid="AverageMonthlyIncome" />{" "}
                        <span className="required">*</span>
                      </label>
                      <input
                        type="number"
                        value={AvgMonthly}
                        size="35"
                        id="AvgMonthly"
                        name="AvgMonthly"
                        onChange={e => setAvgMonthly(e.target.value)}
                      />
                      {AvgMonthlyValidationMessgae &&
                        <div className='alert alert-danger' style={{ width: "65%" }}> <Text tid="FieldRequired" /> </div>
                      }
                    </div>

                    <div className="mr-4 m-4">
                      <label htmlFor="AvgMonthly">
                      <Text tid="PhoneNumber" />{" "}
                        <span className="required">*</span>
                      </label>
                      <input
                        type="number"
                        value={phoneNumber}
                        size="35"
                        id="phoneNumber"
                        name="phoneNumber"
                        placeholder='+XX XXXX XXXX'
                        onChange={e => setPhoneNumber(e.target.value)}
                      />
                      {phoneNumberValidationMessgae &&
                        <div className='alert alert-danger' style={{ width: "65%" }}> <Text tid="FieldRequired" /> </div>
                      }
                    </div>

                    <div className="mr-4 m-4">
                      <label htmlFor="contactName">
                        <Text tid="Name" /> <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        value={contactName}
                        size="35"
                        id="contactName"
                        name="contactName"
                        onChange={e => setContactName(e.target.value)}
                      />
                      {contactNameValidationMessgae &&
                        <div className='alert alert-danger' style={{ width: "65%" }}> <Text tid="FieldRequired" /> </div>
                      }
                    </div>

                    <div className="mr-4 m-4">
                      <label htmlFor="contactEmail">
                        <Text tid="Email" /> <span className="required">*</span>
                      </label>
                      <input
                        type="email"
                        pattern=".+@globex\.com"
                        value={contactEmail}
                        size="35"
                        id="contactEmail"
                        name="contactEmail"
                        onChange={e => setContactEmail(e.target.value)}
                        required
                      />
                      {contactEmailValidationMessgae &&
                        <div className='alert alert-danger' style={{ width: "65%" }}> <Text tid="FieldRequired" /> </div>
                      }
                    </div>

                    <div className="mr-4 m-4">
                      <label htmlFor="contactSubject"> <Text tid="Subject" /></label>
                      <input
                        type="text"
                        value={subject}
                        size="35"
                        id="contactSubject"
                        name="contactSubject"
                        onChange={e => setSubject(e.target.value)}
                      />
                      {subjectValidationMessgae &&
                        <div className='alert alert-danger' style={{ width: "65%" }}> <Text tid="FieldRequired" /> </div>
                      }
                    </div>

                    <div className="mr-4 m-4">
                      <label htmlFor="contactMessage">
                        <Text tid="Message" /> <span className="required">*</span>
                      </label>
                      <textarea
                        cols="50"
                        rows="5"
                        id="contactMessage"
                        name="contactMessage"
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                      ></textarea>
                      {messageValidationMessgae &&
                        <div className='alert alert-danger' style={{ width: "65%" }}> <Text tid="FieldRequired" /> </div>
                      }
                    </div>

                    <div className="mr-4 m-4">
                      <button className="submit" onClick={handleOnSubmit}> <Text tid="submit" /></button>

                    </div>

                  </div>




                </div>
              </Slide>
            </div>
            <div className="col-md-4 col-lg-4 col-12 p-0 address-wrapper">
              <Slide right duration={1000}>
                <aside className=" footer-widgets">
                  <div className="widget widget_contact">
                    <div className="row flex-wrap mt-4 pt-2">
                      <div className="col-12 mb-2">
                        <div className="col-10 pr-4 pl-4 mr-4">
                          <h2
                            className="text-light "
                            style={{ padding: "0 11px" }}
                          >
                            <Text tid="WhereToFindUs" />
                          </h2>
                        </div>
                        <div className="row pt-4 pm-4">
                          <div className="col-2 text-center">
                            <span>
                              <FaMapMarkerAlt />
                            </span>
                          </div>
                          <div className="col-10">
                            <Text tid="Address1" />
                          </div>
                        </div>
                        <div className="col-12 mb-2">
                        <div className="row pt-4 pm-4">
                          <div className="col-2 text-center">
                            <span>
                              <FaPhoneAlt />
                            </span>
                          </div>
                          <div className="col-10">
                            <span style={{ direction: "ltr", display: "inline-block" }}> +971 2 666 1399</span>
                          </div>
                        </div>
                      </div>
                        <div className="row pt-4 pm-4">
                          <div className="col-2 text-center">
                            <span>
                              <FaMapMarkerAlt />
                            </span>
                          </div>
                          <div className="col-10">
                            <Text tid="Address2" />
                          </div>
                        </div>
                        <div className="col-12 mb-2">
                        <div className="row pt-4 pm-4">
                          <div className="col-2 text-center">
                            <span>
                              <FaPhoneAlt />
                            </span>
                          </div>
                          <div className="col-10">
                            <span style={{ direction: "ltr", display: "inline-block" }}>+971 4 835 9710</span>
                          </div>
                        </div>
                      </div>
                      </div>
                      <div className="col-12 mb-4">
                        <div className="row pt-4 pm-4">
                          <div className="col-2 text-center">
                            <span>
                              <FaMobileAlt />
                            </span>
                          </div>
                          <div className="col-10">
                            <span style={{ direction: "ltr", display: "inline-block" }}> +971 50 806 8300</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </aside>
                <div>
                  <SimpleMap />
                </div>
              </Slide>
            </div>
          </div>
        </div>
      </Fade>
    </section>

  );
}

export default Contact;
