import en from './en.json';
import ar from "./ar.json";

export const dictionaryList = { en, ar };

export const languageOptions = {
  en: 'English',
  ar: 'اللغة العربية',

};
