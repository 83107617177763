import axios from "axios";


// // Add a request interceptor
// axios.interceptors.request.use(function (config) {
//     // Do something before request is sent
//     return config;
// }, function (error) {
//     // Do something with request error
//     return Promise.reject(error);
// });

// Add a response interceptor
axios.interceptors.response.use(function (response, error) {

    console.log("Response: ", response);
    console.log("Response data: ", response.data);
    console.log("Response Error: ", error);

    if (!response) {
        throw (error)
    }

    if (response?.data?.result?.response_code === 200 || response?.data?.response_code === 200) {
        //handle success response
        return { data: response?.data?.result || null, error: null };
    } else {
        //console.log("ERROR: ", response?.data);
        return { data: null, error: { message: response?.data?.result?.error || response?.data?.result?.message || "Something went worng, try again later" } };
        //handle error response
    }
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    //return response;
}, function (error) {
    console.log("Throw ERROR: ", error);
    return { data: null, error: { message: "Something went worng, try again later" } };
});


const Http = {
    post: (url, data) => {
        // console.log("request url: ", `${ConfigService.getBaseUrl()}/${url}`);
        return axios.post(url, data);
    }
}

export default Http