import React, { Component } from "react";
import Slide from "react-reveal";
import { FaPaperPlane } from "react-icons/fa";
import services from "../static/img/services.jpg"; 
import workProcessAnalysis from "../static/img/workProcessAnalysis.jpg"; 
import tax from "../static/img/tax.jpg";
import businessCasesPreparation from "../static/img/businessCasesPreparation.jpg"; 
import Auditing from "../static/img/Auditing.jpg";
import administative from "../static/img/administative.jpg";
import { Text, LanguageContext } from '../containers/Language';

class Resume extends Component {
  render() {
    return (
      <section id="services">
        <h3
          className=" our-services-title"
          style={{ fontSize: "32px", textAlign: "center" }}
        >
          <Text tid="Services"/>
        </h3>
        <div className="row d-flex flex-wrap mr-4 ml-4 our-sevices-container align-items-center justify-content-center">
          <div className="col-md-5 col-lg-4 col-12 col-service-wrapper">
            <Slide left duration={1300}>
              <div className="services-content ">
              <p className="service-img-wrapper">
                  <img src={businessCasesPreparation} className="service-img"/>
                </p>
                <h3>
                <Text tid="serviceTitle1"/>
                </h3>
                <div className="services-content-p">
                  <ul>
                    <li>
                    <Text tid="serviceTitle1Li1"/>
                    </li>
                    <li> <Text tid="serviceTitle1Li2"/></li>
                    <li>
                    <Text tid="serviceTitle1Li3"/>
                    </li>
                    <li>
                    <Text tid="serviceTitle1Li4"/>
                    </li>

                    <li>  <Text tid="serviceTitle1Li5"/></li>
                  </ul>
                </div>
              </div>
            </Slide>
          </div>
          <div className="col-md-5 col-lg-4 col-12 col-service-wrapper">
            <Slide left duration={1300}>
              <div className="services-content ">
                <p className="service-img-wrapper">
                  <img src={workProcessAnalysis} className="service-img"/>
                </p>
                <h3>    <Text tid="serviceTitle2"/></h3>
                <div className="services-content-p">
                  <ul>
                    <li> <Text tid="serviceTitle2Li1"/></li>
                    <li>  <Text tid="serviceTitle2Li2"/></li>
                    <li> <Text tid="serviceTitle2Li3"/></li>
                    <li> <Text tid="serviceTitle2Li4"/></li>
                    <li>
                    <Text tid="serviceTitle2Li5"/>
                    </li>
                    {/* <li>
                    <Text tid="serviceTitle2Li6"/>
                    </li> */}
                  </ul>
                </div>
              </div>
            </Slide>
          </div>
          <div className="col-md-5 col-lg-4 col-12 col-service-wrapper">
            <Slide left duration={1300}>
              <div className="services-content ">
              <p className="service-img-wrapper">
                  <img src={services} className="service-img"/>
                </p>
                <h3> <Text tid="serviceTitle3"/></h3>
                <div className="services-content-p">
                  <ul>
                    <li> <Text tid="serviceTitle3Li1"/></li>
                    <li>  <Text tid="serviceTitle3Li2"/></li>
                    <li>
                    <Text tid="serviceTitle3Li3"/>
                    </li>
                    <li>
                    <Text tid="serviceTitle3Li4"/>
                    </li>
                    <li>
                    <Text tid="serviceTitle3Li5"/>
                    </li>
                    <li>
                    <Text tid="serviceTitle3Li6"/>
                    </li>
                  </ul>
                </div>
              </div>
            </Slide>
          </div>
          <div className="col-md-5 col-lg-4 col-12 col-service-wrapper">
            <Slide left duration={1300}>
              <div className="services-content ">
                    <p className="service-img-wrapper">
                  <img src={tax} className="service-img"/>
                </p>
                <h3> <Text tid="serviceTitle4"/> </h3>
                <div className="services-content-p">
                  <ul>
                    <li> <Text tid="serviceTitle4Li1"/></li>
                    <li> <Text tid="serviceTitle4Li2"/></li>
                    <li> <Text tid="serviceTitle4Li3"/></li>
                    <li><Text tid="serviceTitle4Li4"/></li>
                    <li><Text tid="serviceTitle4Li5"/></li>
                    <li><Text tid="serviceTitle4Li6"/></li>
                    <li><Text tid="serviceTitle4Li7"/></li>
                    <li><Text tid="serviceTitle4Li8"/></li>
                    <li><Text tid="serviceTitle4Li9"/></li>
                  </ul>
                </div>
              </div>
            </Slide>
          </div>
          <div className="col-md-5 col-lg-4 col-12 col-service-wrapper">
            <Slide left duration={1300}>
              <div className="services-content ">
              <p className="service-img-wrapper">
                  <img src={Auditing} className="service-img"/>
                </p>
                <h3> <Text tid="serviceTitle5"/> </h3>
                <div className="services-content-p">
                  <ul>
                    <li><Text tid="serviceTitle5Li1"/></li>
                    <li><Text tid="serviceTitle5Li2"/></li>
                    <li><Text tid="serviceTitle5Li3"/></li>
                    <li><Text tid="serviceTitle5Li4"/></li>
                  </ul>
                </div>
              </div>
            </Slide>
          </div>

          <div className="col-md-5 col-lg-4 col-12 col-service-wrapper">
            <Slide left duration={1300}>
              <div className="services-content ">
              <p className="service-img-wrapper">
                  <img src={administative} className="service-img"/>
                </p>
                <h3><Text tid="serviceTitle61"/></h3>
                <div className="services-content-p">
                  <ul>
                  <li><Text tid="serviceTitle61Li1"/></li>
                  <li><Text tid="serviceTitle61Li2"/></li>
                  </ul>
                </div>
                <hr/>
                <h3 style={{padding : "0 20px"}}>
                <Text tid="serviceTitle5"/>
                    </h3>
                    <div className="services-content-p">
                      <ul>
                      <li><Text tid="serviceTitle62Li1"/></li>
                      <li><Text tid="serviceTitle62Li2"/></li>
                      <li><Text tid="serviceTitle62Li3"/></li>
                      </ul>
                    </div>
              </div>
            </Slide>
          </div>
        </div>

      </section>
    );
  }
}

export default Resume;
