import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import MyMarker from "./MyMarker";
import pin from "../static/img/pin.png";
const AnyReactComponent = ({ text }) => <div>
  <img src={pin} />
</div>;
const points = [
  { id: 1, title: "Round Pond", lat: 51.506, lng: -0.184 }
];
class SimpleMap extends Component {

  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
  }

  static defaultProps = {
    center: {
      lat: 24.499900,
      lng: 54.367750
    },
    zoom: 15
  };



  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyAR4IFkrYl4b-JZXEtnpGFddAnheElBQas",
            language: "en"
          }}
          defaultCenter={this.props.center}
          defaultZoom={15}
          yesIWantToUseGoogleMapApiInternals
      >
          <AnyReactComponent
            lat={24.499900}
            lng={54.367750}
            text="My Marker"
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default SimpleMap;