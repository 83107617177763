import React, { Component } from "react";
import Fade from "react-reveal";

class Footer extends Component {
  render() {

    return (
      <footer>
        <div className="row">

            <div className="d-flex align-items-center justify-content-center">
              <ul className="social-links">
                <li>
                  <a href="https://www.facebook.com/Excellent3113/" target="_blank">
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/uaetaxagent" target="_blank">
                    <i className="fa fa-twitter"></i>
                  </a>
                </li>

                <li>
                  <a href="https://instagram.com/excellent_eftc?utm_medium=copy_link" target="_blank">
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/excellentuae" target="_blank">
                    <i className="fa fa-linkedin"></i>
                  </a>
                </li>


              </ul>
            </div>


          <div id="go-top">
            <a title="Back to Top" href="#home">
              <i className="icon-up-open"></i>
            </a>
          </div>
        </div>
        <div style={{ padding: "20px 0",
    borderTop: "1px solid #f2f2f2", background:"#121652", color : "#f2f2f2"}}>
        Excellent Financial and Tax Consultancy | © 2021, All rights reserved

        </div>
      </footer>
    );
  }
}

export default Footer;
