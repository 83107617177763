import React, { useContext, useState } from 'react';
import { Text, LanguageContext } from '../containers/Language';
import homeImg from "../static/img/homeImg.png";
import Fade from "react-reveal";
import { FaCaretRight } from 'react-icons/fa';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import LanguageSelector from './LanguageSelector';
import { Carousel } from 'react-responsive-carousel';

const Header = () => {

  return (
    <header id="home">
      <nav id="nav-wrap">
        <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
          Show navigation
          </a>
        <div className="Wrapper-image logo-mobile"><div className="elementor-image img-header-logo"></div></div>
        <a className="mobile-btn" href="#home" title="Hide navigation">
          Hide navigation
          </a>
        <div className="row d-flex align-items-center justify-content-center pt-3 pb-2 top-header ">

          <div className="col-md-6 col-lg-4 col-12">
            <div>
            <span><Text tid="ContactUs" /> : </span>
            <span style={{direction: "ltr", display: "inline-block"}}> +971 50 806 8300</span>
            <span> | info@excellentuae.net</span>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 col-12 d-flex align-items-center justify-content-around">
            <ul className="social-links">
              <li className="d-inline-block" style={{ margin: "0 5px" }}><a href="https://www.facebook.com/Excellent3113/" target="_blank"><i className="fa fa-facebook text-primary"></i>
              </a></li>
              <li className="d-inline-block" style={{ margin: "0 5px" }}><a href="https://twitter.com/uaetaxagent" target="_blank"><i className="fa fa-twitter text-primary"></i></a></li>
              <li className="d-inline-block" style={{ margin: "0 5px" }}><a href="https://instagram.com/excellent_eftc?utm_medium=copy_link" target="_blank"><i className="fa fa-instagram text-primary"></i></a></li>
              <li className="d-inline-block" style={{ margin: "0 5px" }}><a href="https://www.linkedin.com/company/excellentuae" target="_blank"><i className="fa fa-linkedin text-primary"></i></a></li>
            </ul>
            <div className="selection-language">
              <LanguageSelector />
            </div>
          </div>


        </div>

        <ul id="nav" className="nav align-items-center text-center justify-content-center">
          <li className="wrapper-image-li">
            <div className="Wrapper-image wrapper-image-w "><div className="elementor-image-w img-header-logo"></div></div>
          </li>

          <li>
            <a href="#home">    <Text tid="Home" /></a>
          </li>

          <li>
            <a href="#about"><Text tid="AboutUs" /></a>
          </li>

          <li>
            <a href="#services"><Text tid="Services" /></a>
          </li>

          <li>
            <a href="#OurTeam"><Text tid="OurTeam" /> </a>
          </li>

          <li>
            <a href="#contact"><Text tid="ContactUs" /> </a>
          </li>
          <li className="">
          <div className="selection-language-mobile-wrapper">
          <div className="selection-language ">
              <LanguageSelector />
            </div>
          </div>
         
          </li>
        </ul>
      </nav>

      <div className="row banner">
        <div className="site-blocks-cover" style={{ overflow: "hidden" }}>
          <div className="">
            <div className="row d-flex flex-wrap">
              <div className="col-md-12 col-12 pt-4">

                <div className="banner-text">
                  <Carousel>
                    <div>
                      <Fade bottom duration={1200}>
                        <h3>
                          <Text tid="ReliableAccounting" />
                        </h3>
                      </Fade>
                      <Fade bottom duration={1210}>
                        <h3 className="head-content p-0">
                          <Text tid="ReliableAccountingParagraph" />
                        </h3>
                        <h3 className="head-content mt-5">
                          <a className="customize-btn m-0" href="#about"><Text tid="ReadMore" /></a>
                        </h3>
                      </Fade>
                    </div>
                    <div>

                      <Fade bottom duration={1220}>
                        <h3 className="mt-0">
                          <Text tid="OurApproach" />
                        </h3>
                      </Fade>
                      <Fade bottom duration={1230}>
                        <h3 className="head-content p-0" style={{ fontSize: "18px !important" }}>
                          <Text tid="OurApproachParagraph" />
                        </h3>
                        <h3 className="head-content mt-5">
                          <a className="customize-btn m-0" href="#about"> <Text tid="ReadMore" /> </a>
                        </h3>
                      </Fade>
                    </div>
                    <div>
                      <Fade bottom duration={1240}>
                        <h3 className="mt-0"> <Text tid="TaxExperts" /> </h3>
                      </Fade>
                      <Fade bottom duration={1250}>
                        <h3 className="head-content p-0" style={{ fontSize: "18px !important" }}>
                          <Text tid="TaxExpertsParagraph" />
                        </h3>
                        <h3 className="head-content mt-5">
                          <a className="customize-btn m-0" href="#about">  <Text tid="ReadMore" /> </a>
                        </h3>
                      </Fade>
                    </div>
                    <div>

                      <Fade bottom duration={1260}>
                        <h3 className="mt-0">
                          <Text tid="FinancialAndTaxConsultingServices" />
                        </h3>
                      </Fade>
                      <Fade bottom duration={1270}>
                        <h3 className="head-content p-0" style={{ fontSize: "18px !important" }}>
                          <Text tid="FinancialAndTaxConsultingServicesP" />
                        </h3>
                        <h3 className="head-content mt-5">
                          <a className="customize-btn m-0" href="#about"><Text tid="ReadMore" /> </a>
                        </h3>

                      </Fade>

                    </div>
                  </Carousel>
                  <Fade bottom>
                    <h1 className="responsive-headline mb-2 mt-2">
                      <div className="Wrapper-image invisible">
                        <div className="elementor-image img-header-logo"></div>
                      </div>
                    </h1>
                  </Fade>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>

      <p className="scrolldown" style={{ visibility: "hidden" }}>
        <a href="#more-details">
          <i className="icon-down-circle"></i>
        </a>
      </p>
    </header>
  );

}

export default Header;
